/*
export const BASE_URL = 'http://159.89.108.175:82/api/'
export const KEYCLOAK_URL = 'http://159.89.108.175/auth/admin/realms/clare'
*/
export const BASE_URL = 'https://patches.cws.fhpannotator.com/api/' // 'http://161.35.19.96:81/api/'
export const KEYCLOAK_URL = 'https://fhpannotator.com/auth/admin/realms/wounds' // 'http://161.35.19.96/auth/admin/realms/wounds'

export const annotationOptions = [
    { friendlyName: 'Zona de Exsudado *', keyName: 'exsudado', color: '#de3142', group: 'Outros' },
    { friendlyName: 'Zona de Penso', keyName: 'penso', color: '#f57514', group: 'Outros' },
    { friendlyName: 'Marcador', keyName: 'marcador', color: '#9a9a9a', group: 'Outros' },
    { friendlyName: 'Outro', keyName: 'outro', color: '#000000', group: 'Outros' }
]

export const AcquisitionOptions = [
    {
        group: 'Imagem Focada',
        options: [
            { name: 'Sim', keyName: 'focus_sim' },
            { name: 'Não', keyName: 'focus_nao' },
        ],
        required: 1,
        maxSelections:1,
        observations: false
    }, {
        group: 'Artefactos de Iluminação',
        options: [
            { name: 'Ausente(s)', keyName: 'lightartefacts_none', exclusive: true },
            { name: 'Sombra(s)', keyName: 'lightartefacts_sombra', exclusive: false },
            { name: 'Reflexão(ões)', keyName: 'lightartefacts_reflexao', exclusive: false },
        ],
        required: 1,
        maxSelections: 2,
        observations: false
    }, {
	group: 'Exsudado visível',
	options: [
            { name: 'Sim', keyName: 'exsudado_sim' },
            { name: 'Não', keyName: 'exsudado_nao' },
        ],
        required: 1,
        maxSelections: 1,
        observations: false
    }, { 
        group: 'Penso Saturado',
        options: [
            { name: 'Sim', keyName: 'penso_saturado_sim' },
            { name: 'Não', keyName: 'penso_saturado_nao' },
        ],
        required: 1,
        maxSelections: 1,
        observations: false
    }, { 
        group: 'Fuga de Exsudado',
        options: [
            { name: 'Sim', keyName: 'fuga_exsudado_sim' },
            { name: 'Não', keyName: 'fuga_exsudado_nao' },
        ],
        required: 1,
        maxSelections: 1,
        observations: true
    }
]

export const requiredNrWholeImageAnnotations = 5 //per image sample

export const wholeImageAnnotationOptions = []

export const EMPTY_ACQUISITION = {
    datasetId: '',
    ownerId: '',
    sampleId: ''
}

export const ACQUISITION_DEFAULT_METADATA = {
    "ID do Paciente": "", // CodigoUtente
    "ID da Ferida": "", // CodigoFerida
    "Fototipo da pele": "", // FototipoPele
    "Tipologia da ferida": "", // TipologiaFerida
    "Localização da ferida": "", // ZonaAnatomica
    "Ferida Presente desde": "", // FeridaPresenteDesde
    "Data da Primeira Monitorização": "", // DataRegistoFerida
    "ID da instituição": "" // maybe // LocalRegisto
}

export const IMAGE_SAMPLE_METADATA = {
    "Data da Monitorização": "", // DataRegistoIntervencao
    "Tipo de penso": "" // only for Pensos // TipoPenso
}

export const MESSAGES = {
    clinicalDecision: 'Finish Sample Annotation',
    disabledClinicalDecision: 'All Images must be annotated',
    missingImageQuality: 'Todos os campos obrigatórios para a anotação devem estar preenchidos!',
    emptyClinicalDecision: '\t\t ATTENTION ! \r\n No Clinical Decision Selected. \r\n\r\n Are you sure you want to finish ?',
    emptyRequiredField: 'One or more of the required fields are empty !',
    resumeAnnotation: 'Want to Resume the Annotation from the last annotated image? \r\n(This action may take a while)',
    finishButton: '\t\tIn order to conclude the annotation, \r\n Please use the \" FINISH SAMPLE ANNOTATION \" Button.'
}
