import {BASE_URL, IMAGE_SAMPLE_METADATA} from '../constants/index'
import queryString from "query-string"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import { excelDate2JSDate } from '../utils/other'


export const getAllAcquisitions = async () => {
    let acquisitions = []
    await fetch(BASE_URL + 'acquisitions/?size=500', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        acquisitions = data
    })
    return acquisitions
}

export const getAcquisition = async (acqId) => {
    let acquisition = {}
    await fetch(BASE_URL + `acquisitions/${acqId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        acquisition = data
    })
    .catch(err => {
        return err
    })
    return acquisition
}

export async function addNewAcquisition (n_acq, datasetId) {
    if (datasetId) {
        n_acq['datasetId'] = datasetId
    }
    let acq = {}
    await fetch(BASE_URL + 'acquisitions/', {
        method: 'POST',
        body: JSON.stringify(n_acq),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {acq = data})
    .catch(err => {
        return err
    })
    return acq
}

export const updateAcquisition = async (acqId, changes) => {
    let updatedAcquisition = {}
    Promise.all([
        await getAcquisition(acqId)
        .then((acq) => {
            updatedAcquisition = {...acq, ...changes}
        }),
        await fetch(BASE_URL + `acquisitions/`, {
            method: 'PUT',
            body: JSON.stringify(updatedAcquisition),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + keycloak.token
            }
        })
        .then(data => data.json())
        .then(data => {
            return data
        })
        .catch((err) => {
            return err
        })
    ])
}

export const deleteAcquisition = async (acqId) => {
    let reqUrl = BASE_URL + 'acquisitions/' + acqId.toString()
    await fetch(reqUrl, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then().then(data => data)
    .catch(err =>  {
        console.log('error - on acquisition deletion : ', err)
        return err
    })
}

export const getSubject = async(acqId) => {
    await fetch(BASE_URL+`acquisitions/${acqId}/subjects`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        return data
    }).catch(err => {
        console.log('error on getting acquisition subject : ', err)
        return err
    })
}

export async function createSubject (acqId, subj) {
    await fetch(BASE_URL + `acquisitions/${acqId}/subjects/`, {
        method: 'PUT',
        body: JSON.stringify(subj),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => {
        return data.json()
    })
    .then(data => {
        return data
    })
    .catch(err => {
        return err
    })
}

export const addNonAnnotationImage = async (acqId, file) => {
    const reqBody = new FormData()
    let resp
    reqBody.append('imageSample', JSON.stringify({
        type: "ImageSample",
        timestamp: new Date().getTime(),
        tags: ["hemogram"]
    }))
    reqBody.append("image", file)

    let old_Hemograms = []
    await getImageSamples(acqId).then(data => {
        old_Hemograms = data.filter(sample => sample.tags.includes("hemogram"))
    }).then(async () => {
        for (let i = 0; i < old_Hemograms.length; i++) {
            await removeImageSample(acqId, old_Hemograms[i]['id'])
        }
    }).then(async () => {
        await fetch(BASE_URL + `acquisitions/${acqId}/samples/images/`, {
            method: 'POST',
            body: reqBody,
            headers: {
                Accept: '*/*',
                Authorization: 'Bearer ' + keycloak.token
            }
        })
        .then(data => data.json())
        .then(data => {
            resp = data;
            return data
        })
        .catch(err => {
            return err
        })
    })
    return resp
}

export const addImageSample = async (acqId, sampleId, file, meta_data) => {
    let loadedMetadata = IMAGE_SAMPLE_METADATA
    if (meta_data !== undefined) {
        loadedMetadata["Data da Monitorização"] = excelDate2JSDate(meta_data["DataRegistoIntervencao"]) || "",
        loadedMetadata["Tipo de penso"] = meta_data["TipoPenso"]
    }

    const reqBody = new FormData()
    reqBody.append('imageSample', JSON.stringify({
        type: "ImageSample",
        metadata: {
            filename: file.name.replaceAll("%3A", ":"),
            sampleId: sampleId ? sampleId : acqId,
            ...loadedMetadata
        },
        timestamp: new Date().getTime()
    }))
    reqBody.append("image", file)

    await fetch(BASE_URL + `acquisitions/${acqId}/samples/images/`, {
        method: 'POST',
        body: reqBody,
        headers: {
            Accept: '*/*',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => data)
    .catch(err => {
        return err
    })
}

export const getImageSamples = async (acqId) => {
    let imageSamples = []
    await fetch (BASE_URL + `acquisitions/${acqId}/samples/images/?size=-1`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        imageSamples = data
    })
    .catch(err => {
        return err
    })

    return imageSamples
}

export const removeImageSample = async (acqId, sampleId) => {
    await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${sampleId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then().then(data => data)
    .catch(err =>  {
        return err
    })
}

export const getImageSample = async (acqId, sampleId) => {
    let uSample = {}
    await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${sampleId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        uSample = data
    })
    .catch(err => {
        return err
    })
    return uSample
}

export const updateSampleMetadataTags = async (acqId, sampleId, newTags) => {
        await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${sampleId}/metadata`, {
            method: 'POST',
            body: JSON.stringify({tags: newTags}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + keycloak.token
            }
        })
        .then(data => data.json())
        .then(data => {
            return data
        })
        .catch(err =>  {
            console.log(`err`, err)
            return err
        })
}

export const updateSampleMetadataObservations = async (acqId, sampleId, newObserv) => {
    await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${sampleId}/metadata`, {
        method: 'POST',
        body: JSON.stringify({observations: newObserv}),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then(data => data.json())
    .then(data => {
        return data
    })
    .catch(err =>  {
        console.log(`err`, err)
        return err
    })
}

export const updateImageSample = async (acqId, sampleId, changes) => {
    let updatedSample = {}
    Promise.all([
        await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${sampleId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + keycloak.token
            }
        })
        .then(data => data.json())
        .then(data => {
            updatedSample = {...data, ...changes}
        })
        .catch(err => {
            return err
        }),
        await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${sampleId}/metadata`, {
            method: 'POST',
            body: JSON.stringify({tags: ["aa3"]}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + keycloak.token
            }
        })
        .then(data => data.json())
        .then(data => {
            return data
        })
        .catch(err =>  {
            console.log(`err`, err)
            return err
        })
    ])
}

export const getRawImageBlob = (acqId, imageId) => {    
    return fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${imageId}/raw`, {
        method: 'GET',
        headers: {
            Accept: 'image/x-png, image/jpeg',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(resp => resp.blob())
    .then(blob => URL.createObjectURL(blob))
    .catch(err => {
        console.log('error : ', err)
        throw err
    })
}

export const getAcquisitionImagesCount = async (acqId) => {

    return await fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/count`, {
        headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(response => response.json())
    .catch(error => {
        console.error('error:', error);
        throw error;
    })
}

export const getNoWholeImageAnnotationsCount = (acqId) => {
    return fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/count?noWholeImageAnnotations=true`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(response => response.json())
    .catch(error => {
        console.error('error:', error);
        throw error;
    })
}

export const fetchAllImageSamples = (acqId, query) => {
    query = query || {};
  
    return fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/?${queryString.stringify(query)}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + keycloak.token
      }
    })
    .then()
    .then(response => response.json())
    .catch(error => {console.error('error:', error); throw error;});
}

export const exportAcquisitionAnnotations = (acqId, sampleId) => {
    return fetch(`${BASE_URL}acquisitions/${acqId}/export`, {
        method: 'GET',
        headers: {
            Accept: 'application/zip',
            Authorization: 'Bearer ' + keycloak.token
        }
    })
    .then()
    .then(resp => resp.blob())
    .then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = `${sampleId}.zip`
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()  
        a.remove()
    })
    .catch(error => {
        console.log('error :', error)
        throw error
    })
}

export const downloadImageSamples = (acqId, sampleId) => {

    const zip = JSZip()
    const folder = zip.folder(`sampleImages`)

    fetchAllImageSamples(acqId, {size: -1}).then(imageSamples => {
        if (imageSamples.length > 0) {
            for (let i = 0; i < imageSamples.length; i++) {
                const blobPromise = fetch(`${BASE_URL}acquisitions/${acqId}/samples/images/${imageSamples[i]['id']}/raw`, {
                    method: 'GET',
                    headers: {
                        Accept: 'image/x-png, image/jpeg',
                        Authorization: 'Bearer ' + keycloak.token
                    }
                })
                .then(resp => resp.blob())
                .catch(error => {
                    console.log('error : ', error)
                    throw error
                })

                let fileName = imageSamples[i].tags.includes('hemogram')
                    ? 'hemogram'
                    : imageSamples[i]['id']
                let fileExtension = imageSamples[i].mediaType.split('/')[1]

                folder.file(`${fileName}.${fileExtension}`, blobPromise)
            }

            zip.generateAsync({type: "blob"})
                .then(blob => saveAs(blob, `${sampleId}_images`))
                .catch(error => {
                    console.log('error : ', error)
                    throw error
                })
        }
    }).catch(error => {
        console.log('error :', error)
        throw error
    })
}
